<template>
  <div>
    <b-row>
      <b-col>
        <h1 class="font-weight-bolder">
          {{ $t('register', { type: $t('branch') }) }}
        </h1>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="overflow-hidden"
    >
      <b-row>
        <b-col
          cols="12"
          lg="5"
          xl="3"
        >
          <b-card-body>
            <b-row
              class="mb-2"
            >
              <b-col
                sm="12"
              >
                <b-form-group :label="$t('branchName')">
                  <b-form-input
                    v-model="supplier_branch.name"
                    size="lg"
                    class="font-weight-bolder"
                    autocomplete="off"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
              >
                <b-form-group
                  :label="$t('logisticName')"
                >
                  <b-form-input
                    v-model="supplier_branch.logistic_name"
                    size="lg"
                    class="font-weight-bolder"
                    autocomplete="off"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
              >
                <b-form-group :label="$t('representative')">
                  <b-form-input
                    v-model="supplier_branch.contact_name"
                    size="lg"
                    class="font-weight-bolder"
                    autocomplete="off"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
              >
                <b-form-group :label="$t('email')">
                  <b-form-input
                    v-model="supplier_branch.email"
                    size="lg"
                    class="font-weight-bolder"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
              >
                <b-form-group :label="$t('phone')">
                  <b-form-input
                    v-model="supplier_branch.phone"
                    size="lg"
                    class="font-weight-bolder"
                    autocomplete="off"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
              >
                <b-form-group :label="$t('location')">
                  <gmap-autocomplete @place_changed="updateCenter($event)" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div>
                  <b-button
                    variant="outline-primary"
                    class="mr-1"
                    @click="addPath()"
                  >
                    Agregar Cobertura
                  </b-button>
                  <b-button
                    v-if="paths.length"
                    variant="outline-primary"
                    @click="removePath()"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-col>
        <b-col
          cols="12"
          lg="7"
          xl="9"
        >
          <b-row>
            <b-col>
              <div id="root">
                <gmap-map
                  ref="map"
                  :center="center"
                  :zoom="12"
                  style="width: 100%; height: 650px"
                >
                  <gmap-polygon
                    v-if="paths.length > 0"
                    ref="polygon"
                    :paths="paths"
                    :editable="true"
                    :options="newPolygonOptions"
                    @paths_changed="updateEdited($event)"
                    @rightclick="handleClickForDelete"
                  />
                  <gmap-polygon
                    v-for="(area, n) in otherPaths"
                    :key="`area-${n}`"
                    ref="polygon2"
                    :paths="area"
                    :options="polygonOptions"
                  />
                </gmap-map>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          variant="primary"
          size="lg"
          @click="saveNewBranch()"
        >
          {{ $t('save') }}
        </b-button>
      </b-col>
    </b-row>
    <div>
      <div v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'underscore'

export default {
  components: {},

  data() {
    return {
      supplier_branch: {
        name: '',
        logistic_name: '',
        contact_name: '',
        email: '',
        phone: '',
        location: '',
        branch_id: '',
        geofence: '',
        active_status: true,
      },
      branchName: '',
      companyAddress: '',
      // geofence
      center: { lat: 1.38, lng: 103.8 },
      edited: null,
      paths: [],
      otherPaths: [],
      mvcPaths: null,
      errorMessage: null,
      polygonGeojson: '',
      newPolygonOptions: {
        fillColor: '#340668',
        fillOpacity: 0.45,
        strokeWeight: 0,
        strokeColor: '#999',
      },
      polygonOptions: {
        fillColor: '#c00',
        fillOpacity: 0.1,
        strokeWeight: 0,
        strokeColor: '#999',
      },
    }
  },
  computed: {
    polygonPaths() {
      if (!this.mvcPaths) return null

      const paths = []
      for (let i = 0; i < this.mvcPaths.getLength(); i += 1) {
        const path = []
        for (let j = 0; j < this.mvcPaths.getAt(i).getLength(); j += 1) {
          const point = this.mvcPaths.getAt(i).getAt(j)
          path.push({ lat: point.lat(), lng: point.lng() })
        }
        paths.push(path)
      }
      return paths
    },
  },
  watch: {
    // eslint-disable-next-line
    polygonPaths: _.throttle(function (paths) {
      if (paths) {
        this.paths = paths
        this.polygonGeojson = {
          type: 'Polygon',
          coordinates: this.paths.map(path => this.closeLoop(path.map(({ lat, lng }) => [lng, lat]))),
        }
      }
    }, 1000),
  },
  created() {
    this.fetchBranches({ supplierId: this.$route.params.id })
      .then(response => {
        response.data.forEach(x => {
          this.readGeojson(x.geofence)
        })
      })
  },
  mounted() {
    this.$refs.map.$mapPromise.then(map => {
      map.panTo({ lat: 25.673281129320067, lng: -100.3161126 })
    })
  },
  methods: {
    // got the geojson polygon example from here
    // http://xkjyeah.github.io/vue-google-maps/polygon-editing-geojson.html

    ...mapActions('branches', ['addBranch', 'fetchBranches']),
    saveNewBranch() {
      this.addBranch({
        supplierId: this.$route.params.id,
        supplierBranch: {
          ...this.supplier_branch,
          geofence: JSON.stringify({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: this.polygonGeojson,
              },
            ],
          }),
        },
      })
        .then(() => {
          this.$router.go(-1)
        })
    },
    closeLoop(path) {
      return path.concat(path.slice(0, 1))
    },
    removeBranch(index) {
      this.branches.splice(index, 1)
    },
    updateCenter(place) {
      this.supplier_branch.location = place
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
    },
    updateEdited(mvcPaths) {
      this.mvcPaths = mvcPaths
    },
    addPath() {
      // obtain the bounds, so we can guess how big the polygon should be
      const bounds = this.$refs.map.$mapObject.getBounds()
      const northEast = bounds.getNorthEast()
      const southWest = bounds.getSouthWest()
      const center = bounds.getCenter()
      const degree = this.paths.length + 1
      const f = 0.66 ** degree

      // Draw a triangle. Use f to control the size of the triangle.
      // i.e., every time we add a path, we reduce the size of the triangle
      const path = [
        { lng: center.lng(), lat: (1 - f) * center.lat() + (f) * northEast.lat() },
        { lng: (1 - f) * center.lng() + (f) * southWest.lng(), lat: (1 - f) * center.lat() + (f) * southWest.lat() },
        { lng: (1 - f) * center.lng() + (f) * northEast.lng(), lat: (1 - f) * center.lat() + (f) * southWest.lat() },
      ]

      this.paths.push(path)
    },
    removePath() {
      this.paths.splice(this.paths.length - 1, 1)
    },
    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject.getPaths()
          .getAt($event.path)
          .removeAt($event.vertex)
      }
    },
    readGeojson($event) {
      try {
        const v = $event
        const paths = v.coordinates.map(linearRing => linearRing.slice(0, linearRing.length - 1)
          .map(([lng, lat]) => ({ lat, lng })))
        this.otherPaths.push(paths)
        this.errorMessage = null
      } catch (err) {
        this.errorMessage = err.message
      }
    },
  },
}
</script>

<style></style>
